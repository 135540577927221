import { Box, Flex } from '@chakra-ui/react';
import Teaser from './Teaser';
import { TeaserMembershipProps } from '../types';

const teaserWrapCommonStyles = {
  flex: ['0 0 100%', null, null, '0 0 50%'],
  py: [8, null, 16],
  px: [4, null, 8, null, '6%'],
  justifyContent: 'center',
};
// TODO: Mobile styles + some tweaking
const TeaserMembership = ({
  heading,
  buttons,
  wysiwyg,
  content_right: infoBlocks,
}: TeaserMembershipProps) => (
  <Box>
    <Flex
      alignItems="stretch"
      flexWrap="wrap"
      flexDirection={['column-reverse', null, null, 'row']}
      borderRadius="lg"
      overflow="hidden"
    >
      <Flex
        {...teaserWrapCommonStyles}
        alignItems="center"
        flexDirection="column"
        bg="backgroundColorInverted"
      >
        <Teaser
          heading={heading}
          variant="membership"
          wysiwyg={wysiwyg}
          buttons={buttons}
          sx={{
            ul: { mb: 0, paddingLeft: '1.75em' },
            li: {
              paddingLeft: '0.5em',
              listStyleImage: `url("data:image/svg+xml,%3Csvg width='18' height='13' viewBox='0 0 18 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.25 1.25049L6.75 11.75L1.5 6.50049' stroke='%235BB250' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A")`,
            },
          }}
          invertedColors
        />
      </Flex>
      <Flex
        {...teaserWrapCommonStyles}
        alignItems={
          infoBlocks && infoBlocks.some(({ image }) => image)
            ? 'flex-start'
            : 'center'
        }
        flexWrap={['wrap', null, 'nowrap']}
        bg="greenBirch"
      >
        {infoBlocks &&
          infoBlocks?.map(({ heading: infoHeading, image, text }) => (
            <Teaser
              key={`${image?.src || text}`}
              flex={['0 0 50%', null, '1']}
              px={[2, null, 0]}
              _last={{
                mr: 0,
              }}
              heading={infoHeading}
              icon={image?.src}
              variant="info"
              text={text}
              maxWidth="175px"
              gridRowGap={2}
              alignItems="center"
            />
          ))}
      </Flex>
    </Flex>
  </Box>
);

export default TeaserMembership;
